import { Table, useFilterAndHighlight } from "h11-client-component-lib";
import { useCallback, useMemo } from "react";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { BasicRowAction, useEditColumn, useInfoColumn } from "../BasicEnumPage";
import { discountsQuery } from "./graphql/DiscountsQuery";
import { DiscountsQuery } from "@relay-generated/DiscountsQuery.graphql";
import { DiscountTableItem } from "./discountData";

export function DiscountsTable({
  queryRef,
  onRowAction,
  search,
}: {
  queryRef: PreloadedQuery<DiscountsQuery>;
  onRowAction: (action: BasicRowAction, item: DiscountTableItem) => void;
  search: string;
}) {
  const { t } = useTranslation();

  const data = usePreloadedQuery(discountsQuery, queryRef);

  const roles = useMemo(() => [...data.discountList!], [data]);

  const filteredSources = useFilterAndHighlight(roles, search, "name");

  type TableItemType = (typeof filteredSources)[number];

  const infoCallback = useCallback(
    (item: TableItemType) => onRowAction("info", item),
    [onRowAction],
  );
  const editCallback = useCallback(
    (item: TableItemType) => onRowAction("edit", item),
    [onRowAction],
  );

  const infoColumn = useInfoColumn<TableItemType>(infoCallback);
  const editColumn = useEditColumn<TableItemType>(editCallback);

  const columns: ColumnDef<TableItemType>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("title"),
        cell: c => c.row.original.nameHighlighted,
      },
      {
        accessorKey: "code",
        header: t("code"),
        cell: c => c.row.original.nameHighlighted,
      },
      infoColumn,
      editColumn,
    ],
    [infoColumn, editColumn],
  );

  return (
    <Table
      columns={columns}
      defaultSort={{ id: "name", desc: false }}
      data={filteredSources}
    />
  );
}
