import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDomainHotelInfo } from "./DomainHotelInfoContext";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "@store";
import { ReactNode, useEffect, useMemo } from "react";
import { createRelayEnvironment } from "@comm/relay";
import { RelayEnvironmentProvider } from "react-relay";
import { H11AppFrame } from "./H11AppFrame";
import { LocalSessionLoader } from "./LocalSessionLoader";
import { useTranslation } from "react-i18next";

export const H11AppContent = ({ children }: { children: ReactNode }) => {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <H11Helmet />
        <H11RelayProvider>
          <LocalSessionLoader>
            <H11AppFrame>{children}</H11AppFrame>
          </LocalSessionLoader>
        </H11RelayProvider>
      </ReduxProvider>
    </HelmetProvider>
  );
};

const H11RelayProvider = ({ children }: { children: ReactNode }) => {
  const domainHotelInfo = useDomainHotelInfo();
  const { i18n } = useTranslation();

  const relayEnvironment = useMemo(() => {
    return createRelayEnvironment(
      domainHotelInfo.hotelUid,
      () => i18n.language,
    );
  }, [domainHotelInfo.hotelCode]);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      {children}
    </RelayEnvironmentProvider>
  );
};

const H11Helmet = () => {
  const domainHotelInfo = useDomainHotelInfo();
  const { i18n } = useTranslation();

  // It's here because it looks like the best place to have it
  useEffect(() => {
    window.name = "h11_hotel_" + domainHotelInfo.hotelCode;
  }, [domainHotelInfo]);

  return (
    <Helmet
      htmlAttributes={{ lang: i18n.language }}
      title={"H11" + (domainHotelInfo ? " - " + domainHotelInfo.hotelName : "")}
    />
  );
};
