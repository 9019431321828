import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { OAuthCallbackPage } from "../pages/OAuthCallbackPage";
import { ReservationsPage } from "../pages/ReservationsPage";
import { DashboardPage } from "../pages/DashboardPage";
import { UsersPage } from "../pages/users/UsersPage";
import {
  MainRouteErrorBoundary,
  RouteErrorBoundary,
} from "./error/RouteErrorBoundary";
import { RolesPage } from "../pages/roles/RolesPage";
import { H11AppContent } from "../H11AppContent";
import { DomainHotelInfoContextProvider } from "../DomainHotelInfoContext";
import { ReservationSourcesPage } from "../pages/reservationSources/ReservationSourcesPage";
import { DiscountsPage } from "../pages/discounts/DiscountsPage";

export const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        path: "/login/*",
        children: [{ path: "callback", element: <OAuthCallbackPage /> }],
      },
      {
        path: "/",
        element: (
          <DomainHotelInfoContextProvider>
            <Outlet />
          </DomainHotelInfoContextProvider>
        ),
        children: [
          {
            path: "/",
            element: (
              <H11AppContent>
                <Outlet />
              </H11AppContent>
            ),
            errorElement: <MainRouteErrorBoundary />,
            children: [
              { path: "/", index: true, element: <Navigate to="/user" /> },
              {
                path: "dashboard",
                element: <DashboardPage />,
              },
              {
                path: "/reservation",
                element: <ReservationsPage />,
              },
              {
                path: "/user",
                element: <UsersPage />,
              },
              {
                path: "/role",
                element: <RolesPage />,
              },
              {
                path: "/reservationSource",
                element: <ReservationSourcesPage />,
              },
              {
                path: "/discount",
                element: <DiscountsPage />,
              },
              {
                path: "*",
                element: <div style={{ padding: 20 }}>Not implemented yet</div>,
              },
            ],
          },
        ],
      },
    ],
  },
]);
