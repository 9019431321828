import { CaretDown, Pencil } from "react-bootstrap-icons";
import { ReservationsPageDummyQuery$data } from "@relay-generated/ReservationsPageDummyQuery.graphql";

const columns = [
  "Tag",
  "Číslo rez.",
  "Název rezervace",
  "Typ pok.",
  "Pokojů",
  "Osob",
  "Příjezd",
  "Odjezd",
  "Zdroj",
  "Cena",
  "Měna",
  "Předplatba",
  "Rooming",
  "Číslo obj.",
  "Země",
  "Opce",
  "Kód hosta",
  "Kód prodeje",
];

export function DummyTable({
  data,
}: {
  data: ReservationsPageDummyQuery$data;
}) {
  /*FIXME předělat na obecnou Table */
  return (
    <div className="Table">
      <table style={{ alignSelf: "stretch" }}>
        <thead>
          <tr>
            <td />
            <td>
              <div className="header-cell">
                <span className="title">Stav</span>
                <span className="controls">
                  <CaretDown />
                </span>
              </div>
            </td>
            <td>
              <div className="header-cell">
                <span className="title">Skup.</span>
                <span className="controls">
                  <CaretDown />
                </span>
              </div>
            </td>
            {columns.map(c => (
              <td key={c}>
                <div className="header-cell">
                  <span className="title">{c}</span>
                  <span className="controls">&nbsp;</span>
                </div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.roomList.map(d => {
            return (
              <tr key={d.code}>
                <td>
                  <Pencil style={{ cursor: "pointer" }} />
                </td>
                <td>
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 8,
                      background: "rgba(0, 172, 17, 1)",
                    }}
                  />
                </td>
                <td>{d.code}</td>
                <td>{d.floor?.code}</td>
                {[...columns].slice(1).map((_, index) => (
                  <td key={index} />
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
