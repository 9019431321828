import {
  Button,
  DateField,
  DateRange,
  DateRangeField,
  FlowGroup,
  FlowLayout,
  FormItem,
  Page,
  SearchField,
  Suspense,
  Tag,
  Tags,
  ToggleButtonGroup,
  ToggleItem,
} from "h11-client-component-lib";
import { t } from "i18next";
import { useState } from "react";
import {
  ArrowCounterclockwise,
  BoxArrowInRight,
  PlusCircle,
  XCircle,
} from "react-bootstrap-icons";
import { DummyTable } from "./DummyTable";
import { graphql, useLazyLoadQuery } from "react-relay";
import { ReservationsPageDummyQuery } from "@relay-generated/ReservationsPageDummyQuery.graphql";

type StateFilterType = "all" | "accommodated" | "valid" | "cancelled";

const dummyFetch = graphql`
  query ReservationsPageDummyQuery {
    roomList {
      code
      floor {
        code
      }
      status
    }
  }
`;

function ReservationsTable() {
  const query = useLazyLoadQuery<ReservationsPageDummyQuery>(dummyFetch, {});

  return <DummyTable data={query} />;
}

function PageFooter() {
  function onClick() {
    alert("TODO");
  }

  // TODO možná nějak zobecnit s FlowLayout a FlowGroup?
  return (
    <footer className="actions">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px 8px",
          justifyContent: "space-between",
        }}>
        <div
          style={{
            display: "flex",
            flexShrink: "1",
            gap: "20px 40px",
            justifyContent: "stretch",
            flexWrap: "wrap",
          }}>
          <div
            style={{
              display: "flex",
              flexShrink: "1",
              gap: 8,
              justifyContent: "stretch",
            }}>
            <Button
              variant="secondary"
              onClick={onClick}
              icon={<XCircle />}
              style={{}}>
              {t("storno")}
            </Button>
            <Button
              variant="secondary"
              onClick={onClick}
              icon={<BoxArrowInRight />}>
              {t("accommodate")}
            </Button>
          </div>
          <div
            style={{
              display: "grid",
              flexShrink: "1",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 8,
            }}>
            <Button variant="secondary" onClick={onClick}>
              {t("confirmations")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("prepayments")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("rooming")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("duplicate")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("proforma_invoices")}
            </Button>
            <Button variant="secondary" onClick={onClick}>
              {t("history")}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            flexShrink: "1",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 8,
          }}>
          <Button variant="secondary" onClick={onClick}>
            {t("rooms_state")}
          </Button>
          <Button variant="secondary" onClick={onClick}>
            {t("group_preview")}
          </Button>
          <Button variant="secondary" onClick={onClick}>
            {t("names")}
          </Button>
          <Button variant="secondary" onClick={onClick}>
            {t("group_cards_print")}
          </Button>
          <Button variant="secondary" onClick={onClick}>
            {t("preview")}
          </Button>
        </div>
      </div>
    </footer>
  );
}

export function ReservationsPage() {
  const [search, setSearch] = useState("");
  const [mainFilter, setMainFilter] = useState<StateFilterType>("all");
  const [stayDateFilter, setStayDateFilter] = useState<Date>();
  const [createdDateRangeFilter, setCreatedDateRangeFilter] =
    useState<DateRange>();

  return (
    <>
      {/*FIXME nějak lépe předělat floating footer, abych nemusel specifikovat výšku*/}
      <Page variant="compact">
        <FlowLayout>
          <div className="page-title Font--section-heading">
            {t("reservations_overview")}
          </div>
          <FlowGroup>
            <div className="Font--regular-acc">{t("active_filter")}:</div>
            <Tags>
              <Tag onDelete={() => alert("FIXME")}>Typ pokoje</Tag>
              <Tag>Typ pokoje</Tag>
            </Tags>
            <div>
              <ArrowCounterclockwise />
            </div>
          </FlowGroup>
          <FlowGroup>
            <div className="Font--regular">
              {t("active_filters_result_row_count")}:
            </div>
            <div className="Font--regular-big">tag</div>
          </FlowGroup>
        </FlowLayout>
        <FlowLayout style={{ marginBottom: 20 }}>
          <SearchField
            value={search}
            onChange={setSearch}
            label={t("search")}
            helperText="Zadejte číslo, název, objednávku, nebo číslo transakce rezervace."
          />
          <ToggleButtonGroup
            label="&nbsp;"
            name="TODO"
            value={mainFilter}
            onChange={setMainFilter}>
            <ToggleItem value="all">{t("all")}</ToggleItem>
            <ToggleItem value="accommodated">{t("accommodated")}</ToggleItem>
            <ToggleItem value="valid">{t("valid")}</ToggleItem>
            <ToggleItem value="cancelled">{t("cancelled")}</ToggleItem>
          </ToggleButtonGroup>

          <DateField
            label={t("stay")}
            style={{ minWidth: 160 }}
            value={stayDateFilter}
            onChange={setStayDateFilter}
          />

          <DateRangeField
            label={t("created")}
            value={createdDateRangeFilter}
            onChange={v => setCreatedDateRangeFilter(v)}
          />

          <FormItem label="&nbsp;">
            <Button onClick={() => alert("TODO")} icon={<PlusCircle />}>
              {t("new_reservation")}
            </Button>
          </FormItem>
        </FlowLayout>

        <Suspense>
          <ReservationsTable />
        </Suspense>
      </Page>
      <PageFooter />
    </>
  );
}
