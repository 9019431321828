import { SelectField, SelectOption } from "h11-client-component-lib";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import { HOTELLESS_DOMAIN } from "@params";

// export const tabName = (hotelCode: string) => "h11_hotel_" + hotelCode;

export function HotelSelector() {
  const chainHotels = useSelector(
    (state: RootState) => state.localSession.chainHotelsList,
  );
  const currentHotel = useSelector(
    (state: RootState) => state.localSession.currentHotel,
  );

  return (
    chainHotels &&
    chainHotels.length > 1 &&
    currentHotel && (
      <SelectField
        value={currentHotel.code}
        onChange={hotelCode => {
          // There was a try (see below) to only focus the tab,
          // when it already exists using window.open("", tabName), but it failed cardinally in Firefox.
          window.open(`https://${hotelCode}.${HOTELLESS_DOMAIN}`, "_self");

          // This opens a new empty tab with name identifying hotel and if it exists
          // (has domain ending with HOTELLESS_DOMAIN), only focuses it.
          // If it doesn't exist, it creates a new one and opens relevant URL.
          /*const newTabName = tabName(hotelCode as string);
          const newTab = window.open("", newTabName);
          try {
            if (newTab && !newTab.location.host.endsWith(HOTELLESS_DOMAIN)) {
              window.open(
                `https://${hotelCode}.${HOTELLESS_DOMAIN}`,
                newTabName,
              );
            }
          } catch (e) {
            // SecurityError happens when accessing frame not opened by this frame and having different domain.
            // But it isn't a problem!
            // When I can't access it,
            // the hotel tab probably already exists with a different domain, so it just gets focused.
            // no-op
          }*/
        }}>
        {chainHotels.map(h => (
          <SelectOption key={h.hotelUid} value={h.code}>
            {h.hotelName}
          </SelectOption>
        ))}
      </SelectField>
    )
  );
}
