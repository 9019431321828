import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cs from "./dict/cs.json";
import en from "./dict/en.json";
import { DEV } from "@params";

i18n
  // TODO pak případně toto, pokud to nebude čistě na uživatelském nastavení
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: DEV,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      cs: {
        translation: cs,
      },
    },
  });
